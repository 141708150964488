import Vue from "vue";
import Vuex from "vuex";
import {
  apiLoginToken,
  apiMemberAll,
  apiUserData,
  apiChangeUserStatus,
  apiProductData,
  apiChangeProductStatus,
  apiContactData,
  apiImpeachData,
  apiSearchImpeach,
  apiSearchActivity,
  apiGetChatRooms,
  apiGetChatMessage,
  apiChangeChatMessageRead,
  apiSendChatMessage,
  apiCreateChatRoom,
  apiGetOrganizeList,
  apiEditUserOrganize,
  apiGetListAd,
  apiAdData,
  apiGetLocationAdList,
  apiLocationList,
} from "@/api/index";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { Base64 } from "js-base64";
import VueCookies from "vue-cookies";
import router from "@/router/index";
import qs from "qs";

import idb from "@/api/idb";
Vue.use(VueCookies);
Vue.$cookies.config("7d");
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    userInfoData: [],
    userImpeachData: [],
    userProductData: [],
    userWantedData: [],
    productInfoData: [],
    productOwnerData: [],
    productTraderData: [],
    productImpeachData: [],
    memberInfoData: [],
    impeachData: [],
    impeachedData: [],
    user: {
      account: "",
      isLogin: false,
      name: "",
    },
    contactData: [],
    activityData: [],
    organizeData: [],
    adData: [],
    cats: [],
    chatRoomList: [],
    messageList: [],
    allMessageList: [],
    chatRoomUser: "",
    locationData: [],
    locationListData: [],
  },
  getters: {
    userImpeachedCount(state) {
      return state.userImpeachData
        .map((item) => parseInt(item.Amount))
        .reduce((a, b) => {
          return a + b;
        }, 0);
    },
    userProductCount(state) {
      return state.userProductData.length;
    },
    userWantedCount(state) {
      return state.userWantedData.length;
    },
    messageCount(state) {
      return state.messageList.length;
    },
  },
  // actions操作行為，不直接修改state的狀態，非同步的行為在這裡面操作
  actions: {
    login({ commit }, { Account, Password }) {
      const data = qs.stringify({
        Account,
        Password: Base64.encode(Password),
      });

      apiLoginToken(data)
        .then((res) => {
          if (res.data.Message == "Success") {
            $cookies.set("myneighborBackendToken", res.data.access_token);
            localStorage.setItem("myneighborBackendAccount", Account);
            localStorage.setItem("myneighborBackendIsLogin", true);
            router.push("/dashboard");
          }
        })
        .catch((error) => {
          // console.log(error);
          Swal.fire({
            icon: "error",
            title: error?.response?.data?.Message,
            showConfirmButton: true,
            confirmButtonColor: "#ff6666",
          });
        });
    },
    logout({ commit }) {
      commit("SET_USER", res.data.ListWebAccount);
    },
    getMemberData({ commit }, { intMode, Account }) {
      const data = qs.stringify({
        intMode,
        Account,
      });
      apiMemberAll(data).then((res) => {
        commit("SET_MEMBERINFODATA", res.data.ListWebAccount);
      });
    },
    getMemberAccount({ commit }, { intMode, Account }) {
      const data = qs.stringify({
        intMode,
        Account,
      });
      apiMemberAll(data).then((res) => {
        commit("SET_USER", res.data.ListWebAccount[0]);
      });
    },
    changeMemberStatus({ commit }, { intMode, Account, Name, EMail, Enabled }) {
      const data = qs.stringify({
        intMode,
        Account,
        Name,
        EMail,
        Enabled,
      });

      Swal.fire({
        title: Enabled == "N" ? "確定停用此帳號？" : "確定啟用此帳號",
        icon: "warning",
        confirmButtonText: "確定",
        confirmButtonColor: "#ff6666",
        showCancelButton: true,
        cancelButtonText: "取消",
      }).then((result) => {
        if (result.isConfirmed) {
          apiMemberAll(data).then((res) => {
            if (res.data.Message == "Success") {
              Swal.fire({
                icon: "success",
                title: "變更成功",
                showConfirmButton: false,
                timer: 1500,
              }).then(() => {
                const data = {
                  intMode: 1,
                  Account: this.state.memberInfoData[0].Account,
                };
                this.dispatch("getMemberData", data);
              });
            }
          });
        }
      });
    },
    updateMemberPassword({ commit }, { Password, PasswordOld }) {
      const data = qs.stringify({
        intMode: 3,
        Account: this.state.memberInfoData[0].Account,
        Name: this.state.memberInfoData[0].Name,
        Password: Base64.encode(Password),
        PasswordOld: Base64.encode(PasswordOld),
        Enabled: this.state.memberInfoData[0].Enabled,
      });

      apiMemberAll(data).then((res) => {
        if (res.data.Message == "Success") {
          Swal.fire({
            icon: "success",
            title: "變更成功",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      });
    },
    getUserData({ commit }, id) {
      const data = qs.stringify({
        UserID: id,
      });
      apiUserData(data).then((res) => {
        commit("SET_USERINFODATA", res.data.DetailUser);
        commit("SET_USERIMPEACHDATA", res.data.ListDetailImpeach);
        commit("SET_USERPRODUCTDATA", res.data.ListDetailProduct);
        commit("SET_USERWANTEDDATA", res.data.ListDetailSecond);
      });
    },
    changeUserOrganize({ commit }, { UserID, EditType, JoinCode }) {
      const data = qs.stringify({
        EditType,
        UserID,
        JoinCode,
      });

      Swal.fire({
        title:
          qs.parse(data).EditType === "Remove"
            ? "確定退出組織"
            : "確定新增組織",
        icon: "warning",
        confirmButtonText: "確定",
        confirmButtonColor: "#ff6666",
        showCancelButton: true,
        cancelButtonText: "取消",
      }).then((result) => {
        if (result.isConfirmed) {
          apiEditUserOrganize(data).then((res) => {
            if (res.data.Message === "Success") {
              Swal.fire({
                icon: "success",
                title:
                  qs.parse(data).EditType === "Remove"
                    ? "退出成功"
                    : "新增成功",
                showConfirmButton: false,
                timer: 1500,
              }).then(() => {
                this.dispatch("getUserData", UserID);
              });
            } else {
              Swal.fire({
                icon: "error",
                title:
                  this.$qs.parse(data).EditType === "Remove"
                    ? "退出失敗"
                    : "新增失敗",
                showConfirmButton: false,
                timer: 1500,
              });
            }
          });
        }
      });
    },
    changeUserStatus({ commit }, { Enabled, UserID, Comment, Birth }) {
      const data = qs.stringify({
        Enabled,
        UserID,
        Comment,
        Birth,
      });

      Swal.fire({
        title: "確定變更使用者資料",
        icon: "warning",
        confirmButtonText: "確定",
        confirmButtonColor: "#ff6666",
        showCancelButton: true,
        cancelButtonText: "取消",
      }).then((result) => {
        if (result.isConfirmed) {
          apiChangeUserStatus(data).then((res) => {
            if (res.data.Message == "Success") {
              Swal.fire({
                icon: "success",
                title: "變更成功",
                showConfirmButton: false,
                timer: 1500,
              }).then(() => {
                this.dispatch("getUserData", UserID);
              });
            }
          });
        }
      });
    },
    getProductData({ commit }, id) {
      const data = qs.stringify({
        ProductID: id,
      });
      apiProductData(data).then((res) => {
        commit("SET_PRODUCTINFODATA", res.data.DetailProduct);
        commit("SET_PRODUCTOWNERDATA", res.data.DetailOwner);
        commit("SET_PRODUCTTRADERDATA", res.data.DetailTrader);
        commit("SET_PRODUCTIMPEACHDATA", res.data.ListDetailImpeach);
      });
    },
    changeProductStatus(
      { commit },
      { ProductID, StatusID, FinishUserID, Comment }
    ) {
      const data = qs.stringify({
        ProductID,
        StatusID,
        FinishUserID,
        Comment,
      });

      switch (StatusID) {
        case 2: {
          if (FinishUserID == "") {
            Swal.fire({
              icon: "warning",
              title: "請輸入交易對象ID",
              showConfirmButton: true,
            });
          }

          break;
        }

        case 6: {
          if (FinishUserID == "") {
            Swal.fire({
              icon: "warning",
              title: "請輸入交易對象ID",
              showConfirmButton: true,
            });
          }

          break;
        }

        default: {
          apiChangeProductStatus(data).then((res) => {
            if (res.data.Message == "Success") {
              Swal.fire({
                icon: "success",
                title: "變更成功",
                showConfirmButton: false,
                timer: 1500,
              }).then(() => {
                this.dispatch("getProductData", ProductID);
              });
            }
          });
        }
      }

      // if(StatusID == '2' && FinishUserID == '') {

      //     Swal.fire({
      //         icon: 'warning',
      //         title: '請輸入交易對象ID',
      //         showConfirmButton: true,
      //     })

      //     if(StatusID == '6' && FinishUserID == '') {
      //         Swal.fire({
      //             icon: 'warning',
      //             title: '請輸入交易對象ID',
      //             showConfirmButton: true,
      //         })
      //     }

      // }else {
      //     apiChangeProductStatus(data).then(res => {
      //         if(res.data.Message == 'Success') {
      //             console.log(123)
      //             Swal.fire({
      //                 icon: 'success',
      //                 title: '變更成功',
      //                 showConfirmButton: false,
      //                 timer: 1500
      //             }).then(() => {
      //                 this.dispatch('getProductData', ProductID)
      //             })
      //         }
      //     })
      // }
    },
    getContactData({ commit }, { ContactUsId }) {
      const data = qs.stringify({
        ContactUsId,
      });
      apiContactData(data).then((res) => {
        commit("SET_CONTACTDATA", res.data.listContactUs);
      });
    },

    getImpeachData({ commit }, { ImpeachID }) {
      const data = qs.stringify({
        ImpeachID,
      });
      // console.log(data);

      apiImpeachData(data).then((res) => {
        if (res.data.Message === "Success") {
          // console.log(res.data.DetailImpeach);
          const impeached = {
            ImpeachedUserID: res.data.DetailImpeach.ImpeachedUserID,
            ImpeachedUserName: res.data.DetailImpeach.ImpeachedNickName,
            ImpeachedUserImageLink: res.data.DetailImpeach.ImpeachedUserImage,
            ImpeachedUserGoodNeighborPoint:
              res.data.DetailImpeach.ImpeachedNeighborPoint,
          };
          const impeach = {
            ImpeachUserID: res.data.DetailImpeach.ImpeachUserID,
            ImpeachUserName: res.data.DetailImpeach.ImpeachNickName,
            ImpeachUserImageLink: res.data.DetailImpeach.ImpeachUserImage,
            ImpeachUserGoodNeighborPoint:
              res.data.DetailImpeach.ImpeachNeighborPoint,
          };
          const impeachList = {};
          commit("SET_IMPEACHEDDATA", impeached);
          commit("SET_IMPEACHDATA", impeach);

          // console.log(impeach);
        }
      });

      // axios
      //   .all([
      //     apiImpeachData(data).then((res) => {
      //       console.log(res.data.ListImpeach);
      //       return res.data.ListImpeach[0];
      //     }),
      //     apiSearchImpeach(data).then((res) => {
      //       // const impeachedData = {
      //       //   ImpeachedUserID: res.data.ListImpeach[0].ImpeachedUserID,
      //       //   ImpeachedUserName: res.data.ListImpeach[0].ImpeachedUserName,
      //       //   ImpeachedUserImageLink:
      //       //     res.data.ListImpeach[0].ImpeachedUserImageLink,
      //       // };

      //       const data = qs.stringify({
      //         UserID: res.data.ListImpeach[0].ImpeachedUserID,
      //       });

      //       const impeached = apiUserData(data).then((res) => {
      //         console.log(res.data);
      //         const impeachedData = {
      //           ImpeachedUserID: res.data.DetailUser.UserID,
      //           ImpeachedUserName: res.data.DetailUser.NickName,
      //           ImpeachedUserImageLink: res.data.DetailUser.ListImageLink,
      //           ImpeachedUserGoodNeighborPoint:
      //             res.data.DetailUser.GoodNeighborPoint,
      //         };
      //         return impeachedData;
      //       });

      //       return impeached;
      //     }),
      //   ])
      //   .then(
      //     axios.spread((...res) => {
      //       commit("SET_IMPEACHDATA", res[0]);
      //       commit("SET_IMPEACHEDDATA", res[1]);

      //       console.log(res);
      //     })
      //   );
    },
    getActivityData({ commit }, AnnouncementId) {
      // const data = qs.stringify({
      //   AnnouncementId,
      // });
      apiSearchActivity().then((res) => {
        commit(
          "SET_ACTIVITYDATA",
          res.data.ListAnnouncement.filter(
            (data) => data.Id == AnnouncementId.AnnouncementId
          )
        );
      });
    },
    getAdData({ commit }, AdId) {
      console.log(AdId);
      const data = qs.stringify({
        AdId: AdId,
      });
      apiAdData(data).then((res) => {
        commit("SET_ADDATA", res.data.ad);
      });
      // apiGetListAd(data).then((res) => {
      //   commit(
      //     "SET_ADDATA",
      //     res.data.ListAd.filter((data) => data.AdId == AdId)
      //   );
      // });
    },

    getOrganizeData({ commit }, LocationID) {
      const data = qs.stringify({
        LocationID,
      });
      apiGetOrganizeList(data).then((res) => {
        commit("SET_ORGANIZEDATA", res.data.ListOrganize);
      });
    },

    // 創建聊天室
    createChatRoom({ commit, dispatch }, AskerUserID) {
      const data = qs.stringify({
        AskerUserID,
        ProductID: "-80",
      });
      apiCreateChatRoom(data).then((res) => {
        if (res.data.Message === "Success") {
          console.log("成功創建聊天室");
          console.log(res.data);
          dispatch("getRoomUser", AskerUserID);
        } else {
          console.log(res);
        }
      });
    },

    async getRoomUser({ commit }, AskerUserID) {
      const chatRoomList = await idb.getChatRoomList();

      const data = await chatRoomList.filter(
        (chatroom) => chatroom.AskerUserID === AskerUserID
      );
      commit("SET_CHATROOMUSER", data);
    },

    async checkChatRoomList({ dispatch }, AskerUserID) {
      const chatRoomList = await idb.getChatRoomList();
      if (
        chatRoomList.filter((data) => data.AskerUserID === AskerUserID).length >
        0
      ) {
        return;
      } else {
        dispatch("createChatRoom", AskerUserID);
      }
    },

    async getChatRoomList({ commit, dispatch }) {
      // 取得chatInfo table

      const chatRoomList = await idb.getChatRoomList();

      const timeStamp = Math.max(
        ...(chatRoomList.map((p) => p.LastMessageTime) ?? 0)
      );

      const data = qs.stringify({
        StartTime: chatRoomList.length > 0 ? timeStamp : 0,
      });

      apiGetChatRooms(data).then((res) => {
        if (res.data.Message === "Success") {
          // 判斷有沒有需要更新的訊息
          if (res.data.ListChat.length > 0) {
            res.data.ListChat.filter((data) => {
              if (
                chatRoomList
                  .map((item) => item.AskerUserID)
                  .indexOf(data.AskerUserID) !== -1
              ) {
                const primaryKey =
                  chatRoomList[
                    chatRoomList
                      .map((item) => item.AskerUserID)
                      .indexOf(data.AskerUserID)
                  ].id;

                const updateData = {
                  ...data,
                  id: primaryKey,
                };

                // 更新存在的chatroom
                // console.log("更新存在的chatroom");

                dispatch("updateSelectChatRoom", updateData);
              } else {
                // 新增不存在的chatroom
                // console.log("新增ChatRoom");
                dispatch("addChatRoom", data);
                dispatch("getNewChatRoomList");
              }
            });
          } else {
            // 沒有的話 呈現DB內的聊天室訊息
            commit("SET_CHATROOMLIST", chatRoomList);
          }
        } else {
          console.log(res);
        }
      });

      // // 更新store
    },

    async getAllMessageList({ commit }) {
      const messageList = await idb.getMessageList();

      commit("SET_ALLMESSAGELIST", messageList);
    },

    async getMessageList({ commit, dispatch }, AskerUserID) {
      // 取得所有聊天記錄
      const messageList = await idb.getMessageList();
      // 確認有沒有需要的聊天記錄
      const message = messageList.filter(
        (data) => data.AskerUserID == AskerUserID && data.ProductID == "-80"
      );

      if (message.length > 0) {
        // 更新資料就好
        const timeStamp = Math.max(...message.map((p) => p.CreateTime));
        const data = qs.stringify({
          ProductID: "-80",
          AskerUserID: AskerUserID,
          StartTime: timeStamp,
        });
        apiGetChatMessage(data).then((res) => {
          if (res.data.Message === "Success") {
            // 確認有沒有未讀訊息
            if (res.data.Chat.NotReadCount > 0) {
              const data = qs.stringify({
                ProductID: "-80",
                AskerUserID: AskerUserID,
              });
              dispatch("updateReadStatus", data);
            }
            // 更新Chatroom
            dispatch("updateSelectChatRoom", res.data.Chat);

            res.data.ListMessage.filter((data) => {
              if (
                message
                  .map((item) => item.MessageID)
                  .indexOf(data.MessageID) !== -1
              ) {
                const primaryKey =
                  message[
                    message
                      .map((item) => item.MessageID)
                      .indexOf(data.MessageID)
                  ].id;

                const updateData = {
                  ...data,
                  id: primaryKey,
                };

                // 更新存在的message
                // console.log("更新Message");
                dispatch("updateSelectMessage", updateData);
              } else {
                // 新增不存在的message
                // console.log("新增Message");
                dispatch("addMessage", data);
              }
            });
          } else {
            console.log(res);
          }
        });
      } else {
        const data = qs.stringify({
          ProductID: "-80",
          AskerUserID: AskerUserID,
          StartTime: 0,
        });
        await apiGetChatMessage(data).then((res) => {
          if (res.data.Message === "Success") {
            res.data.ListMessage.map((data) => {
              dispatch("addMessage", data);
            });
          } else {
            console.log(res);
          }
        });
      }

      dispatch("getNewMessageList", AskerUserID);
    },

    async saveChatRoom({ commit, dispatch }) {
      let cats = await idb.getCats();

      if (cats.length === 0) {
        console.log(0);
        apiGetChatRooms().then((res) => {
          if (res.data.Message === "Success") {
            console.log(123);
            res.data.ListChat.forEach((c) => {
              this.state.chatRoomList.push(c);
            });
            this.dispatch("addChatRoom", res.data.ListChat);
          } else {
            console.log(res);
          }
          // 更新store
          commit("SET_CHATROOMLIST", res.data.ListChat);
        });
      } else {
        const data = await idb.getCats();

        commit("SET_CHATROOMLIST", data);
      }
    },

    updateReadStatus({ commit }, params) {
      apiChangeChatMessageRead(params).then((res) => {
        if (res.data.Message === "Success") {
          console.log("已讀");
        } else {
          console.log(res);
        }
      });
    },

    async updateSelectMessage(context, message) {
      await idb.updateSelectChatRoom(message);
    },

    async updateSelectChatRoom(context, chatroom) {
      await idb.updateSelectChatRoom(chatroom);
      this.dispatch("getNewChatRoomList");

      // console.log(data);
      // commit("SET_CHATROOMLIST", data);
    },

    async addMessages({ commit }, message) {
      await idb.addMessage(message);
    },

    async addChatRoom(context, chatroom) {
      await idb.addChatRoom(chatroom);
    },

    // 把最新的ChatRoom存到store
    async getNewChatRoomList({ commit }) {
      const data = await idb.getChatRoomList();
      // console.log("把最新的ChatRoom存到store");
      commit("SET_CHATROOMLIST", data);
    },

    // 把最新的Message存到store
    async getNewMessageList({ commit }, AskerUserID) {
      const data = await idb.getMessageList();
      const message = data
        .filter((msg) => msg.AskerUserID === AskerUserID)
        .sort((a, b) => a.CreateTime - b.CreateTime);

      // console.log("把最新的message存到store");
      // console.log("更新畫面");

      commit("SET_MESSAGELIST", message);
    },

    async saveMessages(context, message) {
      await idb.saveMessage(message);
    },

    async addMessage(context, message) {
      await idb.addMessage(message);
    },

    getLocationData({ commit }) {
      apiLocationList().then((res) => {
        commit("SET_LOCATIONDATA", res.data.ListLocation);
        commit("SET_LOCATIONLISTDATA", res.data.ListAllLocation);
      });
      // apiGetListAd(data).then((res) => {
      //   commit(
      //     "SET_ADDATA",
      //     res.data.ListAd.filter((data) => data.AdId == AdId)
      //   );
      // });
    },
  },

  // mutations操作狀態，不操作非同步行為，如AJAX
  mutations: {
    SET_MESSAGE(state, payload) {
      state.messages = payload.sort((a, b) => {
        return a.CreateTime - b.CreateTime;
      });
    },
    SET_UPDATEMESSAGE(state, payload) {
      state.messages = [...state.messages, payload];
    },
    SET_CHATROOMLIST(state, payload) {
      state.chatRoomList = payload;
    },
    SET_MESSAGELIST(state, payload) {
      state.messageList = payload;
    },
    SET_ALLMESSAGELIST(state, payload) {
      state.allMessageList = payload;
    },
    SET_USER(state, payload) {
      state.user.account = payload.Account;
      state.user.isLogin = true;
      state.user.name = payload.Name;
    },
    DELETE_USER(state, payload) {
      state.user.account = payload.Account;
      state.user.isLogin = payload.isLogin;
      state.user.name = payload.Name;
    },
    SET_MEMBERINFODATA(state, payload) {
      state.memberInfoData = payload;
    },
    SET_USERINFODATA(state, payload) {
      state.userInfoData = payload;
    },
    SET_USERIMPEACHDATA(state, payload) {
      state.userImpeachData = payload;
    },
    SET_USERPRODUCTDATA(state, payload) {
      state.userProductData = payload;
    },
    SET_USERWANTEDDATA(state, payload) {
      state.userWantedData = payload;
    },
    SET_PRODUCTINFODATA(state, payload) {
      state.productInfoData = payload;
    },
    SET_PRODUCTOWNERDATA(state, payload) {
      state.productOwnerData = payload;
    },
    SET_PRODUCTTRADERDATA(state, payload) {
      state.productTraderData = payload;
    },
    SET_PRODUCTIMPEACHDATA(state, payload) {
      state.productImpeachData = payload;
    },
    SET_CONTACTDATA(state, payload) {
      state.contactData = payload;
    },
    SET_IMPEACHDATA(state, payload) {
      state.impeachData = payload;
    },
    SET_IMPEACHEDDATA(state, payload) {
      state.impeachedData = payload;
    },
    SET_ACTIVITYDATA(state, payload) {
      state.activityData = payload;
    },
    SET_CHATROOMUSER(state, payload) {
      state.chatRoomUser = payload;
    },
    SET_ORGANIZEDATA(state, payload) {
      state.organizeData = payload;
    },
    SET_ADDATA(state, payload) {
      state.adData = payload;
    },
    SET_LOCATIONDATA(state, payload) {
      state.locationData = payload;
    },
    SET_LOCATIONLISTDATA(state, payload) {
      state.locationListData = payload;
    },
  },
});

export default store;
